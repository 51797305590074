.wave {
  height: 1em;
  width: 1em;
  margin: 0px 0.05em 0px 0.1em;
  vertical-align: -0.1em;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

.sde {
  color: #572b8b;
  font-weight: bold;
}

.bg-main {
  position: relative;
  display: table;
  width: 100%;
  height: 100vh;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 2rem;
}

.bg-content {
  display: table-cell;
  vertical-align: middle;
  padding-top: 8rem;
  padding-bottom: 6rem;
}

.scrollDown {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #572b8b;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #572b8b;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
