@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: local("Poppins"), local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"), url("./fonts/Agustina.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src: local("Poppins"), local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
}
